import React from 'react';

const UsSection = () => {
  return (
    <div className='banner-app-container value-container'>
      <img className="value-banner pc" src="/assets/img/home-page/valores-pc.png" alt="Banner de comercio electrónico" />
      <img className="value-banner-mobile mobile" src="/assets/img/home-page/valores-mobile.png" alt="Banner de comercio electrónico" />
    </div>
  );
};

export default UsSection;