import React, { useState, useEffect } from 'react';
import Slider from "react-slick"; // importamos el slider de react-slick

const HomeBanner = () => {
  const MarqueeWords = [
    { title: 'Brand experience' },
    { title: 'Visual Merch' },
    { title: 'Display' },
    { title: 'Packaging' },
    { title: 'Etiquetas' },
    { title: 'Institucional' }
  ];

  const [sliderSettings, setSliderSettings] = useState({
    dots: true,
    infinite: true,
    speed: 5000, // velocidad inicial (PC)
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 7000, // duración de cada slide en PC
    cssEase: 'ease-in-out'
  });

  // useEffect para cambiar la configuración según el tamaño de la pantalla
  useEffect(() => {
    const updateSliderSettings = () => {
      if (window.innerWidth <= 768) {
        // Configuración para móvil
        setSliderSettings({
          ...sliderSettings,
          speed: 3000, // más rápido en móvil
          autoplaySpeed: 4000 // cambiar la duración en móvil
        });
      } else {
        // Configuración para PC
        setSliderSettings({
          ...sliderSettings,
          speed: 5000, // velocidad en PC
          autoplaySpeed: 7000 // duración en PC
        });
      }
    };

    // Escuchar el cambio de tamaño de ventana
    window.addEventListener('resize', updateSliderSettings);

    // Llamar a la función para configurar la velocidad correcta inicialmente
    updateSliderSettings();

    // Limpiar el listener cuando el componente se desmonta
    return () => {
      window.removeEventListener('resize', updateSliderSettings);
    };
  }, []);

  return (
    <div className="banner section video-banner">
      {/* Slider de imágenes */}
      <Slider {...sliderSettings}>
        <div>
          <a href="https://bit.ly/MotiCentral" target="_blank">
            <img
              src="assets/img/home-page/homeBannerSlider/Banner 1.png"
              alt="Imagen 1 PC"
              className="slider-image pc"
            />
            <img
              src="assets/img/home-page/homeBannerSlider/Banner 1 Movil.png"
              alt="Imagen 1 Mobile"
              className="slider-image mobile"
            />
          </a>
        </div>
        <div>
          <a href="https://bit.ly/MotiCentral" target="_blank">
            <img
              src="assets/img/home-page/homeBannerSlider/Banner 2.png"
              alt="Imagen 2 PC"
              className="slider-image pc"
            />
            <img
              src="assets/img/home-page/homeBannerSlider/Banner 2 Movil.png"
              alt="Imagen 2 Mobile"
              className="slider-image mobile"
            />
          </a>
        </div>
        <div>
          <a href="https://bit.ly/MotiCentral" target="_blank">
            <img
              src="assets/img/home-page/homeBannerSlider/Banner 3.png"
              alt="Imagen 3 PC"
              className="slider-image pc"
            />
            <img
              src="assets/img/home-page/homeBannerSlider/Banner 3 Movil.png"
              alt="Imagen 3 Mobile"
              className="slider-image mobile"
            />
          </a>
        </div>
      </Slider>

      {/* Marquee con animación */}
      <div className="marquee-wrapper">
        <div className="marquee">
          <div className="marquee-content">
            <span style={{ display: 'inline-block', width: '30rem' }}></span>
            {MarqueeWords.map((word, index) => (
              <span key={index} style={{ marginLeft: '4rem' }}>
                {word.title}
                {index !== MarqueeWords.length - 1 && '  '}
              </span>
            ))}
            <span style={{ display: 'inline-block', width: '30rem' }}></span>
            {MarqueeWords.map((word, index) => (
              <span key={index + MarqueeWords.length} style={{ marginLeft: '4rem' }}>
                {word.title}
                {index !== MarqueeWords.length - 1 && '  '}
              </span>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeBanner;
