import React from 'react';

const Banner = () => {
  return (
    <div className='banner-app-container'>
      <img className="img-app pc" src="/assets/img/home-page/banner-ecommerce-pc.png" alt="Banner de comercio electrónico" />
      <img className="img-app mobile" src="/assets/img/home-page/banner-ecommerce-mobile.png" alt="Banner de comercio electrónico" />
    </div>
  );
};

export default Banner;