import React, { useState } from 'react';
import emailjs from 'emailjs-com';

const ContactSection = () => {
  const [contact, setContact] = useState({ mail: '' });
  const [showMessage, setShowMessage] = useState(false);

  // IDs de EmailJS
  const YOUR_SERVICE_ID = 'service_lxg5sht'; // reemplaza con tu servicio ID de EmailJS
  const YOUR_TEMPLATE_ID = 'template_epehkcc'; // reemplaza con tu template ID de EmailJS
  const YOUR_USER_ID = '4f0iRLKGvmXsd0ASA'; // reemplaza con tu public key en EmailJS

  const handleChange = (e) => {
    const { name, value } = e.target;
    setContact({ ...contact, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Construimos el objeto con las variables que queremos enviar
    const templateParams = {
      to_name: 'equipo Moti',
      from_name: contact.mail, // Este es el correo del cliente
      message: `Me interesan sus productos. Me pueden contactar al correo ${contact.mail}, me estoy contactando desde motidigital.com`,
    };

    emailjs.send(YOUR_SERVICE_ID, YOUR_TEMPLATE_ID, templateParams, YOUR_USER_ID)
      .then((response) => {
        console.log('SUCCESS!', response.status, response.text);
        setContact({ mail: '' }); // Resetea el formulario
        setShowMessage(true); // Mostrar mensaje de éxito
      }, (err) => {
        console.log('FAILED...', err);
      });
  };

  return (
    <div className='main-mailer'>
      <div className="contact-section">
        <h2 className="contact-title">
          Todo en impresión digital: <span className="contact-highlight">Tu marca destacando en todos lados</span>
        </h2>
        <p className="contact-description">
          ¿Las etiquetas premium para tu vino? ¿La lona para el espectacular de tu marca? ¿El empaque para tu campaña con influencers?
        </p>
        <p className="contact-subtext">
          En Moti Digital lo hacemos posible, <strong>solicita tu cotización:</strong>
        </p>
        {showMessage ? (
          <div className="alert alert-success" role="alert">¡Email enviado con éxito!</div>
        ) : (
          <form onSubmit={handleSubmit} className="contact-form">
            <input
              type="email"
              placeholder="Inserta tu correo aquí"
              value={contact.mail}
              name="mail"
              onChange={handleChange}
              className="contact-input"
            />
            <button type="submit" className="contact-button">Empecemos</button>
          </form>
        )}
      </div>
    </div>
  );
};

export default ContactSection;
