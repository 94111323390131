import React from "react";

const Trusted = () => {
  return (
    <div className="main-container-trusted">
      <div className="opacid-overlay-trusted">
        <div className="tittle-trusted">
          <span className="tittle-normal-trusted">Más de 300 compañias globales</span>
          <span className="tittle-bold-trusted">confían en nosotros</span>
        </div>
        <div className="icons-trusted">
          {['Akron', 'Guess', 'Pandora', 'Birdman','SantaSabina', 'Vervan', 'VictoriaSecret', 'AmericanEagle'].map((brand) => (
            <img
              key={brand}
              src={`/assets/img/home-page/trusted/${brand}.png`}
              alt={brand}
              className="icon-trusted"
              loading="lazy"
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Trusted;
