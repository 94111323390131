import React from "react";

const EcommerceBanner = () => {
  const ecommerceImages = [
    {
      src: "assets/img/home-page/motifabrics.png",
      alt: "Motifabrics",
      link: "https://motifabrics.com/",
    },
    {
      src: "assets/img/home-page/motiprints.png",  // Imagen corregida
      alt: "Motiprints",
      link: "https://motiprints.com/",  // Agrega el link adecuado
    },
    {
      src: "assets/img/home-page/motistore.png",
      alt: "Motistore",
      link: "https://www.motistore.com/",
    },
  ];

  return (
    <div className="banner-container">
      <div className="main-ecommerce">
        <div className="tittle-ecommerce">
          <span className="small-tittle-ecommerce">Nuestras marcas</span>
          <span className="big-tittle-ecommerce">Compra Online</span>
          <img src="assets/img/home-page/ecommerce/dots-moti.png" alt="dots moti" className="dots-moti" />
        </div>
        <div className="text-description-ecommerce">
          <div className="description-ecommerce">
            <span className="text-descitption-ecommerce">imprime la decoración<br></br> de tu hogar</span>
            <span className="text-descitption-ecommerce">descarga nuestra app<br></br> e imprime photobooks</span>
            <span className="text-descitption-ecommerce">imprime todo para<br></br> tu negocio</span>
          </div>
          <div className="text-divider-ecommerce">
          </div>
        </div>
        <div className="ecommerce-icons">

          {ecommerceImages.map((image, index) => (
            <a
              key={index}
              href={image.link}
              target="_blank"
              rel="noopener noreferrer"
              className="link-icon"
            >
              <img src={image.src} alt={image.alt} className="icon-commerce" />
            </a>
          ))}
        </div>

      </div>

    </div>
  );
};

export default EcommerceBanner;
