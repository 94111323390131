import React, { useEffect, useState } from 'react';

const Counter = ({ count, counttext, showPlus }) => {
  const target = count !== undefined ? +count : null;
  const speed = 200;
  const increment = target / speed;
  const [currentCount, setCurrentCount] = useState(0);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (target === null) return;
      if (currentCount < target) {
        setCurrentCount(prevCount => prevCount + increment);
      }
    }, 1000 / speed);

    return () => clearTimeout(timer);
  }, [currentCount, target, increment]);

  return (
    <li className="count" data-count={count}>
      <div>
        {showPlus && target !== null && <span>+</span>} {/* Mostrar "+" solo si hay target */}
        {target !== null && (
          <span className="show-count">{Math.ceil(currentCount)}</span>
        )} {/* Mostrar el número solo si hay target */}
      </div>
      <span className="text-count" dangerouslySetInnerHTML={{ __html: counttext }}></span>
    </li>
  );
};

const CountBanner = () => {
  const [animationKey, setAnimationKey] = useState(0);

  const updateCount = () => {
    const tiempoAleatorio = Math.floor(Math.random() * (10000 - 5000 + 1) + 5000);
    setTimeout(() => {
      setAnimationKey(prevKey => prevKey + 1);
    }, tiempoAleatorio);
  };

  useEffect(() => {
    const intervalId = setInterval(updateCount, 5000);

    return () => clearInterval(intervalId);
  }, []);

  return (
    <div>
      <ImageBanner />
      <CounterNumbers key={animationKey} />
    </div>
  );
};

const ImageBanner = () => (
  <div className="banner">
    <img src="assets/img/home-page/img-banner1.png" alt="Banner" />
  </div>
);

const CounterNumbers = () => (
  <div className='main-count-cointainer'>
    <div className='text-counter'>
      <span className='soft-text'>¿por que <strong>moti?</strong></span>
      <span className='main-count-title'>Los números hablan</span>
      <span className='highligth-count-title'><strong>por sí mismos</strong></span>
    </div>

    <ul className="counter-list">
      <Counter count={9} counttext="Tecnologias de impresión" showPlus={true} />
      <Counter count={2500} counttext="Proyectos en<br /> Norteamérica" showPlus={true} />
      <Counter count={19} counttext="Años de experiencia" showPlus={true} />
      <Counter count={1000} counttext="Clientes" showPlus={true} />
      <Counter count={300} counttext="Colaboradores" showPlus={true} />
      <Counter counttext="Certificación<br />ISO" />
    </ul>
  </div>
);

export default CountBanner;
